import _extends from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Link, Typography } from '@material-ui/core';

function ErrorAlert() {
  return __jsx(Box, {
    role: "alert",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }, __jsx(Typography, null, "\u274C Sorry, an error has ocurred.", __jsx(Link, {
    href: window.location.href
  }, "Try reloading the page.")));
}

export default function ErrorAlertBoundary(props) {
  return __jsx(ErrorBoundary, _extends({
    FallbackComponent: ErrorAlert
  }, props));
}